/* MessageForm */

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: none;
  padding: 0;
  resize: none;
  outline: none;
  width: 100%;
  margin-top: 32px;
  padding: 24px;
  height: 200px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.errorMsg {
  color: red;
  padding: 10px 0px;
}

form {
  display: inline-block;
  width: 44%;
}

input {
  width: 100%;
  border: none;
  padding: 24px;
  margin-top: 12px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input:focus {
  border-color: none;
  outline: none;
}

.form-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  margin-left: 5%;
}

.formButton {
  margin-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border-radius: 16px;
  background: -o-linear-gradient(
    30deg,
    rgba(143, 45, 222, 1) 0%,
    rgba(255, 88, 93, 1) 100%
  );
  background: linear-gradient(
    60deg,
    rgba(143, 45, 222, 1) 0%,
    rgba(255, 88, 93, 1) 100%
  );
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border: none;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 28.6px */
  color: var(--White, #fff);
  text-align: center;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
button.formButtonBack {
  width: 100%;
  margin-top: 32px;
  color: #48536c;
  font-size: 26px;
  background: none;
  border: none;
}
button:hover {
  cursor: pointer;
}

.formButton:disabled {
  background: #dbdbdb;
  color: #48536c;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.formButtonBack {
  width: 100%;
  margin-top: 32px;
  color: #48536c;
  font-size: 26px;
  font-family: Optima;
  background: none;
  border: none;
}
.header {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 113%; /* 54.24px */
  color: #0460a9;
}

.subHeader {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
  margin-top: 24px;
  color: #48536c;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.gdpr-outer-container {
  max-width: 1200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 5em;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(239, 245, 250, 85%);
  z-index: 1;
  border-radius: 30px;
  border: 5px solid #e4e6e8;
  -webkit-box-shadow: -2px 13px 32px -12px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 13px 32px -12px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  -webkit-animation-name: gdpr-outer-container-fade-in;
  animation-name: gdpr-outer-container-fade-in;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  top: 50%;
  transform: translateY(-50%);
}

.toggledClass {
  max-width: 1200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 5em;
  margin-left: auto;
  margin-right: auto;
  background-color: #eff5fa;
  z-index: 1;
  border-radius: 30px;
  border: 5px solid #e4e6e8;
  -webkit-box-shadow: -2px 13px 32px -12px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 13px 32px -12px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  -webkit-animation-name: gdpr-outer-container-fade-out;
  animation-name: gdpr-outer-container-fade-out;
  top: 50%;
  transform: translateY(-50%);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.gdpr-inner-container {
  padding: 40px;
}

.gdpr-section {
  padding-bottom: 20px;
}

.gdpr-title {
  font-size: 20px;
  padding-bottom: 20px;
  line-height: 30px;
  font-weight: bold;
  color: #0460a9;
}

.gdpr-subtitle {
  font-size: 18px;
  padding-bottom: 5px;
  line-height: 24px;
  font-weight: bold;
  color: #0460a9;
}
.gdpr-paragraph {
  font-size: 16px;
  line-height: 24px;
  color: #212835;
}
.gdpr-list {
  list-style-type: disc;
  padding-left: 20px;
  padding-top: 10px;
  font-size: 16px;
  line-height: 21px;
  color: #212835;
  font-family: "optima";
}
.gdpr-list li::marker {
  color: #0460a9;
}
.adverse-event-paragraph {
  font-size: 16px;
  color: #0460a9;
  font-weight: bold;
  line-height: 24px;
}
.adverse-event-paragraph a {
  font-size: 16px;
  color: #33c1bc;
  font-weight: bold;
}

.gdpr-glass-effect-container {
  height: 1500px;

  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-transform: rotate(145deg);
  -ms-transform: rotate(145deg);
  transform: rotate(145deg);
  right: 20em;
  top: -25em;
  -webkit-animation-name: gdpr-glass-effect;
  animation-name: gdpr-glass-effect;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-transition: ease-out;
  -o-transition: ease-out;
  transition: ease-out;
}
.gdpr-thin-glass {
  height: 100%;
  background-color: rgb(255 255 255 / 22%);
  width: 40px;
}
.gdpr-thick-glass {
  height: 100%;
  background-color: rgb(255 255 255 / 22%);
  width: 180px;
  margin-left: 25px;
}

.gdpr-closeBtn {
  float: right;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  background: -o-linear-gradient(
    30deg,
    rgba(143, 45, 222, 1) 0%,
    rgba(255, 88, 93, 1) 100%
  );
  background: linear-gradient(
    60deg,
    rgba(143, 45, 222, 1) 0%,
    rgba(255, 88, 93, 1) 100%
  );
  border: 2px solid white;
  color: white;
  right: 0;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  margin: 20px;
  line-height: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gdpr-closeBtn:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.gdpr-display-none {
  display: none;
}

.footer-container-top {
  width: 100%;
  background-color: #0460a9;
  padding: 80px 0;
}
.footer-display-flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 5%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-content-container {
  width: 100%;
  padding-right: 40px;
}
.footer-title {
  font-size: 32px;
  color: #fff;
  padding-bottom: 20px;
}
.footer-paragraph {
  font-size: 24px;
  color: #fff;
  line-height: 34px;
}
.footer-button-container {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}
.footer-button-container a {
  width: 100%;
}
.footer-btn {
  color: #0460a9;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 24px;
  line-height: 34px;
  font-family: Optima;
  font-weight: bold;
  padding: 20px 110px;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  position: relative;
  overflow: hidden;
}
.footer-glass-btn-effect-thick {
  height: 200px;
  width: 120px;
  background-color: #bedcf5;
  -webkit-transform: rotate(160deg);
  -ms-transform: rotate(160deg);
  transform: rotate(160deg);
  position: absolute;
  top: -2em;
  left: -7em;
  opacity: 0.2;
}

.footer-btn:hover .footer-glass-btn-effect-thick {
  -webkit-animation-name: footer-glass-btn-effect-thick;
  animation-name: footer-glass-btn-effect-thick;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
.footer-btn:hover {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.footer-container-bottom {
  width: 100%;
  background-color: #f2f2f4;
}
.footer-display-flex-container-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 20px;
}

.footer-info {
  font-size: 25px;
  line-height: 34px;
  font-family: Optima;
  color: #0460a9;
}
.footer-separator {
  height: 22px;
  width: 2px;
  background-color: #0460a9;
  margin: 0px 10px;
}

@-webkit-keyframes footer-glass-btn-effect-thick {
  0% {
    left: -7em;
  }
  100% {
    left: 15em;
  }
}

@keyframes footer-glass-btn-effect-thick {
  0% {
    left: -7em;
  }
  100% {
    left: 15em;
  }
}

@-webkit-keyframes gdpr-outer-container-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes gdpr-outer-container-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes gdpr-outer-container-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gdpr-outer-container-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes gdpr-glass-effect {
  0% {
    right: -50em;
  }
  100% {
    right: 20em;
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }
}

@keyframes gdpr-glass-effect {
  0% {
    right: -50em;
  }
  100% {
    right: 20em;
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-container {
  position: relative;
}

.formOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 5px solid #f2f2f4;
  border-top: 5px solid #0460a9;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
}
.thank-you-message {
  max-width: 577px;
  -webkit-animation: fadeIn 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 1350px) {
  .footer-button-container {
    width: 55%;
  }
}

@media (max-width: 1100px) {
  .footer-button-container {
    width: 100%;
  }
  .thank-you-message {
    margin: 0 auto;
    padding: 0 5%;
  }
  .gdpr-outer-container {
    margin: 5%;
    top: 50px;
    transform: unset;
  }
  .toggledClass {
    margin: 5%;
    top: 50px;
    transform: unset;
  }
  .footer-display-flex-container {
    margin: 0 auto;
    width: 80%;
  }
  .footer-display-flex-container {
    display: block;
  }
  .footer-btn {
    padding: 20px 0px;
    width: 100%;
    margin-top: 30px;
  }
  @-webkit-keyframes footer-glass-btn-effect-thick {
    0% {
      left: -7em;
    }
    100% {
      left: 35em;
    }
  }
  @keyframes footer-glass-btn-effect-thick {
    0% {
      left: -7em;
    }
    100% {
      left: 35em;
    }
  }
}
@media (max-width: 700px) {
  .form-container {
    height: auto;
    padding: 50px 0px;
  }
  .gdpr-outer-container {
    background-color: rgba(239, 245, 250, 95%);
  }
}

@media (max-width: 500px) {
  .footer-info {
    font-size: 18px;
  }
  .footer-separator {
    height: 15px;
  }
}

@media (max-height: 680px) {
  .form-container {
    height: auto;
    padding: 40px 0px;
  }
  .formTree {
    display: none;
  }
  form .header {
    font-size: 30px;
  }
  form .subHeader {
    font-size: 18px;
  }
  form .input-like-textarea {
    font-size: 18px;
  }
  form input {
    font-size: 18px;
  }
  .formButton {
    font-size: 18px;
  }
  .footer-title {
    font-size: 30px;
  }
  .footer-paragraph {
    font-size: 18px;
  }
  .footer-button-container button {
    font-size: 18px;
  }
}
