.formTree {
	position: absolute;
	bottom: 0;
	height: 100%;
	width: auto;
	right: 0;
	z-index: -1;
}
.formPage {
	animation: fadeIn 0.5s ease-in-out;
}
.formPage .logo {
	width: 100%;
}
@media (max-width: 1300px) {
	.formTree {
		height: 93%;
		width: auto;
	}
}

@media (max-width: 1100px) {
	.formTree {
		display: none;
	}
	form {
		width: 80%;
		margin: 0 auto;
	}
	.form-container {
		margin-left: unset;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
