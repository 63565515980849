/* GratitudeTree */

.avocadoTree {
	width: 55%;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}
.avocadoTree:hover {
	cursor: pointer;
}

.content {
	text-align: center;
	position: relative;
	height: 100vh;
}

.heading {
	color: #0460a9;
	text-align: center;
	font-size: 56px;
	font-weight: 400;
	line-height: 113%;
	margin-top: 4%;
}

.subHeading {
	color: #48536c;
	text-align: center;
	font-size: 32px;
	font-weight: 400;
	line-height: 123%;
	margin-top: 32px;
}

.logo {
	margin-top: 3%;
}

.messageButton {
	z-index: 2;
	border-radius: 50%;
	border: 3px solid #fff;
	background: -o-linear-gradient(
		30deg,
		rgba(143, 45, 222, 1) 0%,
		rgba(255, 88, 93, 1) 100%
	);
	background: linear-gradient(
		60deg,
		rgba(143, 45, 222, 1) 0%,
		rgba(255, 88, 93, 1) 100%
	);
	width: 155px;
	height: 155px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	right: 20px;
	bottom: 20px;
	color: #fff;
	text-align: center;
	font-family: Montserrat, sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 120%;
	position: fixed;
	margin-left: auto;
	bottom: 5%;
	overflow: hidden;
	cursor: pointer;
}

.glasseffectbold {
	width: 50px;
	height: 200px;
	background-color: #fff;
	opacity: 0.6;
	-webkit-transform: rotate(160deg);
	-ms-transform: rotate(160deg);
	transform: rotate(160deg);
	position: absolute;
	overflow: hidden;
	left: -3em;
}

.glasseffectthin {
	width: 10px;
	height: 200px;
	background-color: #fff;
	opacity: 0.6;
	-webkit-transform: rotate(160deg);
	-ms-transform: rotate(160deg);
	transform: rotate(160deg);
	position: absolute;
	overflow: hidden;
	left: -3em;
}

.messageButton:hover .glasseffectbold {
	-webkit-animation-name: glasseffectbold;
	animation-name: glasseffectbold;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

.messageButton:hover .glasseffectthin {
	-webkit-animation-name: glasseffectthin;
	animation-name: glasseffectthin;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

/*animations*/

.animation .wrap,
.animation .logo,
.animation img,
.animation {
	-webkit-animation: 1.5s ease forwards;
	animation: 1.5s ease forwards;
}

.animation {
	overflow-x: hidden;
}

.animation .wrap {
	animation-name: fade-out-messages;
}
.animation .logo {
	animation-name: logo-up;
}
.animation img {
	animation-name: img-scale;
}

@-webkit-keyframes glasseffectthin {
	0% {
		left: -4em;
	}
	100% {
		left: 7em;
	}
}

@keyframes glasseffectthin {
	0% {
		left: -4em;
	}
	100% {
		left: 7em;
	}
}

@-webkit-keyframes glasseffectbold {
	0% {
		left: -3em;
	}
	100% {
		left: 8em;
	}
}

@keyframes glasseffectbold {
	0% {
		left: -3em;
	}
	100% {
		left: 8em;
	}
}

@-webkit-keyframes fade-out-messages {
	0% {
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0;
		display: none;
	}
}

@keyframes fade-out-messages {
	0% {
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0;
		display: none;
	}
}

@-webkit-keyframes logo-up {
	0% {
	}
	100% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		margin-top: 2%;
	}
}

@keyframes logo-up {
	0% {
	}
	100% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		margin-top: 2%;
	}
}

@-webkit-keyframes img-scale {
	0% {
	}
	70% {
		opacity: 0.9;
	}
	100% {
		width: 180%;
		display: none;
		opacity: 0;
	}
}

@keyframes img-scale {
	0% {
	}
	70% {
		opacity: 0.9;
	}
	100% {
		width: 180%;
		display: none;
		opacity: 0;
	}
}

/* Smaller screens */

@media (max-width: 1200px) {
	.avocadoTree {
		width: 65%;
	}
	@-webkit-keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 200%;
			display: none;
			opacity: 0;
		}
	}
	@keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 200%;
			display: none;
			opacity: 0;
		}
	}
}

/* tablet portrait */
@media (max-width: 1200px) and (orientation: portrait) {
	.avocadoTree {
		width: 100%;
	}
	@-webkit-keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 300%;
			display: none;
			opacity: 0;
		}
	}
	@keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 300%;
			display: none;
			opacity: 0;
		}
	}
}

@media (max-width: 992px) {
	.avocadoTree {
		width: 85%;
	}
	.heading {
		font-size: 51px;
	}
	.subHeading {
		font-size: 28px;
	}
	.logo {
		width: 200px;
	}
	@-webkit-keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 250%;
			display: none;
			opacity: 0;
		}
	}
	@keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 250%;
			display: none;
			opacity: 0;
		}
	}
}

@media (max-width: 992px) and (orientation: portrait) {
	.avocadoTree {
		width: 100%;
	}
	@-webkit-keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 400%;
			display: none;
			opacity: 0;
		}
	}
	@keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 400%;
			display: none;
			opacity: 0;
		}
	}
}
@media (width: 1024px) and (max-height: 668px) {
	.avocadoTree {
		width: 50%;
	}
}
@media (max-width: 932px) and (orientation: landscape) {
	img.avocadoTree {
		height: auto;
		position: relative;
		left: unset;
		right: unset;
		-webkit-transform: unset;
		-ms-transform: unset;
		transform: unset;
	}
	@-webkit-keyframes img-scale {
		0% {
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			position: absolute;
		}
		70% {
			opacity: 0.9;
		}

		100% {
			display: none;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			width: 180%;
			height: auto;
			opacity: 0;
			position: absolute;
		}
	}
	@keyframes img-scale {
		0% {
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			position: absolute;
		}
		70% {
			opacity: 0.9;
		}

		100% {
			display: none;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			width: 180%;
			height: auto;
			opacity: 0;
			position: absolute;
		}
	}
}
/* mobile */

@media (max-width: 767px) and (orientation: portrait) {
	.avocadoTree {
		width: 85%;
	}
	.heading {
		font-size: 47px;
	}
	.subHeading {
		font-size: 24px;
	}
	@-webkit-keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 350%;
			display: none;
			opacity: 0;
		}
	}
	@keyframes img-scale {
		0% {
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 350%;
			display: none;
			opacity: 0;
		}
	}
}

@media (max-width: 576px) {
	.mobile-hide {
		display: none;
	}
	.logo {
		width: 140px;
	}
	.avocadoTree {
		width: 100%;
		height: auto;
	}
	.heading {
		font-size: 28px;
		margin-top: 58px;
		padding: 0 16px;
	}
	.subHeading {
		font-size: 18px;
		padding: 0 16px;
	}

	@-webkit-keyframes img-scale {
		0% {
			width: 100%;
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 300%;
			display: none;
			opacity: 0;
		}
	}

	@keyframes img-scale {
		0% {
			width: 100%;
		}
		70% {
			opacity: 0.9;
		}
		100% {
			width: 300%;
			display: none;
			opacity: 0;
		}
	}
}

@media (max-height: 800px) and (max-width: 1550px) {
	.avocadoTree {
		height: auto;
	}
}

@media (max-width: 950px) and (max-height: 500px) {
	.messageButton {
		top: 0px;
		bottom: unset;
		margin-top: 10px;
	}
	.heading {
		font-size: 45px;
	}
	.messageButton {
		width: 100px;
		height: 100px;
		font-size: 16px;
	}
	.glasseffectbold {
		left: -4em;
	}
}
