/* Messages */
.messagesContainer {
  display: none;
  width: 100%;
  margin: 0 auto;
}

.messagesWrapper {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(33%), 1fr));
  max-width: 1400px;
  margin: 0 auto;
}

.messageCard {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  -webkit-transform: translateY(150px);
  -ms-transform: translateY(150px);
  transform: translateY(150px);
}

.messageCard.parallax-show {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.messageCard .textContainer {
  position: absolute;
  width: 14em;
  text-align: left;
  word-wrap: break-word;
  color: white;
  font-size: 24px;
  z-index: 2;
}

.messageCard .textContainer p {
  margin-top: 32px;
}
.leaf {
  width: 40em;
}
.quoteIcon {
  width: 45px;
}
.messageCard .textContainer .name {
  font-size: 18px;
}

.messageCard:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.overlay {
  position: fixed;
  top: -50%;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 1000%;
  z-index: 1;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
}

.closeBtn {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  padding: 4px 9px;
  border-radius: 50%;
  background: -o-linear-gradient(
    30deg,
    rgba(143, 45, 222, 1) 0%,
    rgba(255, 88, 93, 1) 100%
  );
  background: linear-gradient(
    60deg,
    rgba(143, 45, 222, 1) 0%,
    rgba(255, 88, 93, 1) 100%
  );
  border: 2px solid white;
  color: white;
  right: 0;
  top: 15%;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.closeBtn:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.animate {
  -webkit-animation: fade-in-messages 1.2s ease forwards;
  animation: fade-in-messages 1.2s ease forwards;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  display: block !important;
  opacity: 0;
}

.footer {
  position: relative;
}

.footer-leaf {
  -webkit-animation: test 1.2s ease forwards;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  margin-top: 200px;
}

@-webkit-keyframes test {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes test {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 914px) and (orientation: landscape), (max-width: 1100px) {
  .footer-leaf.absOnl {
    position: relative;
  }
}
.footer-leaf {
  opacity: 0;
  bottom: 0;
  width: 100%;
  margin-top: 120px;
  z-index: -1;
  text-align: left;
}
@-webkit-keyframes fadeInFooter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInFooter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 914px) and (orientation: landscape) {
  .footer-leaf {
    margin-top: 96px;
  }
}
@-webkit-keyframes fade-in-messages {
  0% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    margin-top: 100px;
  }
}
@keyframes fade-in-messages {
  0% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    margin-top: 100px;
  }
}

/* smaller screens */
@media (max-width: 1500px) {
  .leaf {
    width: 31em;
  }
  .messagesWrapper {
    max-width: 1000px;
  }
  .messageCard .textContainer {
    font-size: 20px;
    width: 14em;
  }
}

@media (max-width: 1200px) {
  .messagesWrapper {
    grid-template-columns: repeat(auto-fit, minmax(calc(50%), 1fr));
    max-width: 650px;
  }
  .messageCard .textContainer {
    font-size: 18px;
    width: 14em;
  }
  .leaf {
    width: 30em;
  }
  .messageCard .textContainer p {
    margin-top: 16px;
  }
  .quoteIcon {
    width: 40px;
  }
}

@media (max-width: 900px) {
  .messagesWrapper {
    grid-template-columns: repeat(auto-fit, minmax(calc(50%), 1fr));
    max-width: 600px;
  }
  .messageCard .textContainer {
    font-size: 16px;
    width: 14em;
  }
  .leaf {
    width: 25em;
  }
  .messageCard .textContainer p {
    margin-top: 16px;
  }
  .quoteIcon {
    width: 40px;
  }
  .messageCard .textContainer .name {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .messagesWrapper {
    grid-template-columns: repeat(auto-fit, minmax(calc(50%), 1fr));
  }
  .messageCard .textContainer {
    font-size: 18px;
    width: 14em;
  }
  .messageCard .textContainer p {
    margin-top: 12px;
  }
  .quoteIcon {
    width: 35px;
  }
}

/* mobile */
@media (max-width: 576px) {
  .messageCard .textContainer {
    font-size: 18px;
    width: 42%;
  }
  .messagesWrapper {
    grid-template-columns: repeat(auto-fit, minmax(calc(90%), 1fr));
  }
  .messageCard .textContainer p {
  }
  .messageCard.parallax-show {
    margin-bottom: -20px;
  }
  .messageCard:hover {
    cursor: pointer;
    z-index: 999 !important;
  }
  .leaf {
    width: 30em;
  }
}
